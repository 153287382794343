
import { defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useRouter } from "vue-router";

import CFormInputText from "@/components/form/InputText.vue";
import { UserRecoverPassword } from "@/store/user/types";

const VRecoverPassword = defineComponent({
  name: "VRecoverPassword",
  components: { CFormInputText },
  setup() {
    useHead({ title: "Recupere sua conta logo abaixo| obmed" });

    const store = useStore();
    const router = useRouter();
    const show = reactive({ password: false });

    const form = reactive<UserRecoverPassword>({
      ds_token: String(router.currentRoute.value.query.token),
      ds_password1: "",
      ds_password2: "",
    });
    const loading = reactive({ submit: false });

    async function handleRecoverPassword() {
      loading.submit = true;
      const response = await store.dispatch("recoverPassword", { form });
      loading.submit = false;

      if (response?.status === 200) router.replace({ name: "root" });
    }

    return { form, loading, show, handleRecoverPassword };
  },
});

export default VRecoverPassword;
